import { axios } from "./../plugins/axios";
export function getBlindBox() {
  return axios.request({
    params: {
      module: "points",
      action: "box",
      app: "box_list"
    }
  });
}

export function swiper() {
  return axios.request({
    params: {
      module: "points",
      action: "box",
      app: "luck_list"
    }
  });
}
