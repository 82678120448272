<template>
  <div class="blindbox">
    <download />
    <div class="content">
      <div class="blind-box">
        <img
          @click="openbox"
          src="./../../assets/img/blindBox/boxs.png"
          alt=""
        />
        <!-- <input class="iphone" type="text" placeholder="请输入手机号" />
        <input class="codenum" type="text" placeholder="请输入验证码" />
        <button class="registe">注册</button> -->
      </div>
      <!-- 播报 -->
      <van-notice-bar scrollable background="none" :text="txttext" />
      <!-- 盲盒清单 -->
      <div class="manghelist" @click="openbox">
        <img src="./../../assets/img/blindBox/boxlist.png" />
        <div class="recordstyle">
          <div v-for="(item, index) in blindData" :key="index" class="jiangpin">
            <img :src="item.image" mode="" />
            <div class="txt1">{{ item.title }}</div>
            <div class="txt2">{{ item.marking }}</div>
          </div>
        </div>
        <div class="zhanwei">
          <!-- 站位盒子 -->
        </div>
      </div>

      <!--  -->
    </div>
    <div class="bottom-btn">
      <button @click="openbox">注册APP，开盲盒</button>
    </div>
  </div>
</template>

<script>
import { getBlindBox, swiper } from "./../../api/blindBox";
import download from "./component/download";
import { getIp, postShare } from "./../../api/report";
import MobileDetect from "mobile-detect";
export default {
  components: { download },
  created() {
    this.getDev();
    this.getBlindBoxData();
    this.getswiperData();
    getIp().then(res => {
      if (res.data.code == 200) {
        postShare({
          ip: res.data.data,
          uid: this.$route.query.user_id || "",
          rid: this.$route.query.rid || "",
          dev: this.dev,
          systemversion: this.systemversion // 版本号
        });
      }
    });
  },
  data() {
    return {
      blindData: [],
      txttext: "",
      dev: "",
      systemversion: ""
    };
  },
  methods: {
    getDev: function() {
      //判断数组中是否包含某字符串
      function contains(arr, str) {
        for (i in arr) {
          if (arr[i].indexOf(str) > 0) return i;
        }
        return -1;
      }

      var device_type = navigator.userAgent; //获取userAgent信息
      var md = new MobileDetect(device_type); //初始化mobile-detect
      console.log(md, "md");
      var os = md.os(); //获取系统
      console.log(os, "os");
      var model = "";
      if (os == "iOS") {
        //ios系统的处理
        os = md.os() + md.version("iPhone");
        model = md.mobile();
      } else if (os == "AndroidOS") {
        //Android系统的处理
        os = md.os() + md.version("Android");
        var sss = device_type.split(";");
        var i = contains(sss, "Build/");
        if (i > -1) {
          model = sss[i].substring(0, sss[i].indexOf("Build/"));
        }
      }
      this.dev = model; // 手机型号
      this.systemversion = os; // 系统版本

      // console.log('手机型号', model);
      // console.log('系统版本', os);
    },
    async getBlindBoxData() {
      const res = await getBlindBox();
      this.blindData = res.data.data;
      console.log(this.blindData, 88);
    },
    async getswiperData() {
      const res = await swiper();
      res.data.data.forEach(item => {
        this.txttext = `${item.user_name}获得${item.prize_name}`;
      });
    },
    openbox() {
      window.location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc";
    }
  }
};
</script>
<style lang="scss" scoped>
.zhanwei {
  width: 100%;
  height: 200px;
}
.bottom-btn {
  width: 100%;
  height: 172px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 41px 35px;
  button {
    width: 683px;
    height: 90px;
    background: linear-gradient(-30deg, #f45749 0%, #ff7674 98%);
    box-shadow: 0px -4px 9px 0px rgba(246, 93, 81, 0.46);
    border-radius: 45px;
    border: none;
    font-size: 38px;
    color: #ffffff;
  }
}
.content {
  margin-top: -3.46667rem;
  background: url(./../../assets/img/blindBox/bgc.png) 0 0 no-repeat;
  background-size: 100%;
  min-height: 180vh;
  background-color: #f8f9fd;
  position: relative;
  /deep/.van-notice-bar {
    position: absolute;
    top: 1338px;
    width: 100%;
    .van-notice-bar__content {
      background-color: #919296;
      color: #fff;
      border-radius: 20px;
      padding: 0 20px;
    }
  }
  /* /deep/.van-notice-bar {
    position: absolute;
    top: 1338px;
    // left: 0;
    z-index: 20;
    height: 0;
    .van-notice-bar__wrap {
      overflow: unset;
    }
    .van-notice-bar__content {
      background-color: #919296;
      color: #fff;
      border-radius: 20px;
    }
  } */
  .blind-box {
    position: absolute;
    top: 622px;
    left: 51px;
    img {
      width: 648px;
      height: 678px;
    }
    .iphone {
      width: 396px;
      height: 76px;
      background: #ffffff;
      border-radius: 38px;
      border: none;
      position: absolute;
      top: 288px;
      left: 44px;
      text-align: center;
      color: #b1b1b1;
    }
    .codenum {
      position: absolute;
      top: 381px;
      left: 44px;
      text-align: center;
      width: 396px;
      height: 76px;
      background: #ffffff;
      border-radius: 38px;
      border: none;
      color: #b1b1b1;
    }
    .registe {
      position: absolute;
      top: 493px;
      left: 44px;
      text-align: center;
      width: 396px;
      height: 76px;
      background: linear-gradient(0deg, #fcff00 0%, #fef7ca 100%);
      box-shadow: 1px 6px 5px 0px rgba(87, 87, 87, 0.18);
      border-radius: 38px;
      border: none;
    }
  }
}
.manghelist {
  width: 711px;
  height: 722px;
  position: absolute;
  top: 1410px;
  left: 19px;
  img {
    width: 711px;
    height: 120px;
  }
  .recordstyle {
    border: 12px solid #ffd05b;
    //border-top: none;
    height: 610px;
    margin-top: -12px;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border-radius: 0px 0px 23px 23px;
    .overtxt {
      font-size: 24px;
      color: #a6a6a6;
      margin-top: 0px;
      margin-bottom: 26px;
    }
    .jiangpin {
      border-radius: 14px;
      width: 153px;
      height: 229px;
      margin: 18px 8px 18px;
      background-color: #fff0dd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 153px;
        height: 139px;
        padding: 0;
      }
      .txt1 {
        font-size: 22px;
        color: #333333;
        margin: 12px 0 13px;
        //	margin: 13px 54px;
        //text-align: center;
      }
      .txt2 {
        color: #8a8989;
        font-size: 19px;
        margin-bottom: 14px;
      }
    }
  }
}
</style>
